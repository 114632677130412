<template>
    <div>
        <a-modal
            :visible="visible"
            title="错题上报"
            :confirmLoading="reportError.data.loading"
            @ok="reportError.onOK"
            @cancel="reportError.onClose"
            >
            <a-form layout="vertical"  ref="formRef" :model="reportError.data" >
            <a-form-item label="试题" 
            >
                <a-select
                    v-model:value="reportError.data.questionId"
                    show-search
                    optionFilterProp="label"
                    placeholder="请选择试题"
                    style="width: 100%"
                    :options="source.questions"
                >
                </a-select>
            </a-form-item>
            <a-form-item label="描述"
            >
                <a-textarea v-model:value="reportError.data.intro" 
                showCount :maxlength="1000"  placeholder="请填写描述以帮助我们改进，不低于10字" />
            </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
import { ref, reactive, getCurrentInstance,watch } from "vue";
import { saveQuestionReport} from "@/api/exam";

export default {
    components: {
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        id: {
            type: Number,
            default: 0,
        },
        taskId: {
            type: Number,
            default: 0,
        },
        questionId: {
            type: Number,
            default: 0,
        },
        source: {
            type:Object,
            default: {},
        },
    },
    emits: ['close', 'ok'],
    setup(props, { emit } ) {
        const { proxy } = getCurrentInstance();
        watch(
            () => props.id,
            (newValue) => {
                reportError.data.id = newValue;
            }
        );

        watch(
            () => props.visible,
            (newValue) => {
                if(newValue){
                    reportError.data.intro = "";
                    reportError.data.loading=false;
                }
            }
        );

        watch(
            () => props.questionId,
            (newValue) => {
                reportError.data.questionId = newValue;
            }
        );
        const formRef = ref();

        const reportError = reactive({
            data: reactive({
                visible: false,
                questions:[],
                questionId: 0,
                intro: '',
                id: 0,
                category: 0,
                name: '',
            }),
            onOK: () => {
                if(reportError.data.intro==""||reportError.data.intro.length<10){
                    proxy.$message.warning('错题描述不能少于10字');
                    return false;
                }
                reportError.data.loading=true;
                let params= {
                    questionId:reportError.data.questionId,
                    intro:reportError.data.intro,
                    category:props.category,
                    name:props.source.name,
                    taskId:props.taskId,
                    detailId:props.source.detailId,
                    courseId:props.source.courseId,
                    reexamId:props.source.reexamId,
                }
                saveQuestionReport( 
                    params
                ).then(res => {
                    proxy.$message.success('提交成功');
                    emit('ok');
                    reportError.data.loading=false;
                });
            },
            onClose: () => {
                emit('ok');
            },
            onChange: () => {
                
            },
        });
        return {
            reportError,
            formRef 
        };
    },
};
</script>